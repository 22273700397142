<template>
	<!-- <div>
		<router-link to="/lo-mejor">
			<img :src="require('@/assets/home/pre.png')" class="w-full rounded-lg">
		</router-link>
	</div> -->

	<Menu />
	<div>
		<vueper-slides autoplay :arrows="false">
			<vueper-slide :image="state.slides[0].image"></vueper-slide>
			<vueper-slide :image="state.slides[1].image"></vueper-slide>
			<vueper-slide :image="state.slides[2].image"></vueper-slide>
			<vueper-slide :image="state.slides[3].image"></vueper-slide>
		</vueper-slides>

		<div class="px-6 pr-6 lg:px-12 bg-repeat" :style="{ backgroundImage: 'url(' + require('@/assets/home/gun-metal.png') + ')' }">
			<div class="md:flex items-center">
				<img class="mt-3 lg:mt-0 w-72 md:w-80 lg:w-96 mx-auto" :src="require('@/assets/home/pantone.png')" alt="">

				<div>
					<div class="-mt-4">
						<h2 class="text-rojo font-semibold text-xl md:text-2xl lg:text-3xl text-center md:text-left">NUESTRO COLOR ES ÚNICO</h2>
						<p class="mt-1 text-xs md:text-sm lg:text-base text-center md:text-left">SOMOS UN EQUIPO DE CREATIVOS Y EXPERTOS EN IMPRESIÓN, INGENIERÍA EN EMPAQUE Y DISEÑO, QUE BUSCA MATERIALIZAR LO QUE QUIERES COMUNICAR.</p>

						<div class="mt-6 text-center md:text-left">
							<a href="https://firebasestorage.googleapis.com/v0/b/redpepper-tempo.appspot.com/o/Redpepper-2021.pdf?alt=media&token=2fdf3c7f-b367-4dbc-ab76-91da07e9c206" target="_blank" download class="text-xs font-medium bg-white rounded-full py-2 lg:py-3 px-5 text-black hover:bg-rojo hover:text-white">
								<DocumentDownloadIcon class="w-5 h-5 inline-block mr-1 mb-1" />
								DESCARGA NUESTRA PRESENTACIÓN
							</a>
						</div>
					</div>
					<div class="mt-8 mb-32 md:mb-12 flex justify-center sm:justify-start">
						<a href="https://api.whatsapp.com/send?phone=+5215559451024&text=Hola%21" target="_blank" class="flex items-center space-x-2">
							<div>
								<img :src="require('@/assets/core/menu/whatsapp.png')" alt="Whatsapp" class="h-12">
							</div>
							<div class="font-semibold text-white text-sm">
								Platiquemos de tu proyecto...
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>	

		<!-- VIDEO -->
		<div class="mx-auto px-6 md:px-20 pt-6 pb-12 lg:py-20 bg-repeat bg-opacity-40" :style="{ backgroundImage: 'url(' + require('@/assets/home/green-fibers.png') + ')' }">
			<div class="lg:grid lg:grid-cols-12 lg:gap-8">
				<div class="sm:text-center lg:text-left lg:col-span-6 sm:pt-6">
					<h1>
						<span class="mt-1 block text-4xl tracking-tight font-bold text-rojo">
							RED PEPPER
						</span>
					</h1>
					<p class="md:mt-1 text-gray-500 text-xl lg:text-2xl font-semibold">
						Somos más que una imprenta.
					</p>
					<p class="md:mt-1 text-gray-100 text-2xl lg:text-3xl font-bold leading-tight">
						Somos una agencia de soluciones gráficas.
					</p>
				</div>

				
				<div class="mt-8 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
					<div class="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
						<button type="button" class="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-azul-light" @click="state.showVideo=true">
							<span class="sr-only">Ve nuestro video</span>
							<img class="w-full" :src="require('@/assets/home/video-screen.png')" alt="" />
							<div class="absolute inset-0 w-full h-full flex items-center justify-center" aria-hidden="true">
								<svg class="h-20 w-20 text-rojo" fill="currentColor" viewBox="0 0 84 84">
									<circle opacity="0.8" cx="42" cy="42" r="42" fill="white" />
									<path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
								</svg>
							</div>
						</button>
					</div>
				</div>
			</div>
		</div>
		
		<div class="flex justify-center">
			<img :src="require('@/assets/home/imagen_prensa.gif')" alt="Redpepper en los medios">
		</div>

		<!-- MAPA -->
		<div class="relative -mt-4 md:mt-0 mb-60 md:mb-80">
			<div class="opacity-70">
				<img class="absolute" :src="require('@/assets/home/mapa_back.jpg')" alt="">
			</div>
			<div class="absolute top-10 md:top-20 w-full text-white" style="text-shadow: 3px 3px 10px #000000;">
				<div class="text-2xl md:text-3xl lg:text-4xl text-center font-bold">
					RED PEPPER,
				</div>
				<div class="text-sm md:text-xl lg:text-2xl text-center font-semibold mt-1 opacity-90">
					EL ÉXITO MEXICANO QUE CAUTIVA AL MUNDO.
				</div>
				<div class="flex justify-center">
					<div class="text-center text-xs md:text-sm font-semibold mt-1 px-4 md:px-12 lg:px-20 opacity-80 max-w-2xl">
						SÉ PARTE DE NUESTRA RED Y CONOCE LAS ÚLTIMAS TENDENCIAS EN CREATIVIDAD E INNOVACIÓN DEL MUNDO DE LA IMPRESIÓN Y LA INGENIERÍA DE EMPAQUE.
					</div>	
				</div>
			</div>
		</div>

		
	</div>

	<ModalVideo v-if="state.showVideo" @close="state.showVideo=false" />
	<ModalShark v-if="state.showShark" @close="state.showShark=false" />
</template>

<script>
import Menu from '@/components/core/Menu'
import { DocumentDownloadIcon } from '@heroicons/vue/solid'
import { VueperSlides, VueperSlide } from 'vueperslides'
import ModalVideo from '@/components/ModalVideo'
import ModalShark from '@/components/ModalShark'
import 'vueperslides/dist/vueperslides.css'
import { reactive } from 'vue'

export default {
	components: {
		Menu,
		DocumentDownloadIcon,
		VueperSlides, 
		VueperSlide,
		ModalVideo,
		ModalShark
	},
	setup() {
		const state = reactive({
			slides: [
				{ image: require('@/assets/home/sliders/amazon.jpg') },
				{ image: require('@/assets/home/sliders/chivas.jpg') },
				{ image: require('@/assets/home/sliders/ferrero.jpg') },
				{ image: require('@/assets/home/sliders/steelers.jpg') }
			],
			showVideo: false,
			showShark: true
		});

		return {
			state
		}
	}
}
</script>

<style scoped>
	.patt {
		background: 
		linear-gradient(135deg, black 25%, transparent 25%) -50px 0,
		linear-gradient(225deg, black 25%, transparent 25%) -50px 0,
		linear-gradient(315deg, black 25%, transparent 25%),
		linear-gradient(45deg, black 25%, transparent 25%);	
		background-size: 2em 2em;
		/* background-color: #232323; */
		background-color: #111111;
	}
</style>

