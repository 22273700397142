<!-- This example requires Tailwind CSS v2.0+ -->
<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" @close="close" :open="open">
			<div class="flex items-end justify-center pt-20 px-2 pb-20 text-center sm:block sm:p-0">
				<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
					<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</TransitionChild>

				<!-- This element is to trick the browser into centering the modal contents. -->
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
				<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
					<div class="inline-block align-bottom bg-white rounded-lg p-1.5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6 cursor-pointer" @click="close">
						<img :src="require('@/assets/home/sharktank.jpg')" alt="" class="rounded-lg">

						<div>
							<button type="button" class="inline-flex justify-center items-center w-full rounded-b-md border border-transparent shadow-sm px-4 py-2 bg-black font-medium text-gray-200 hover:bg-azul-dark hover:text-white focus:outline-none text-xs" @click="close">
								<XIcon class="w-4 h-4 mr-1" />
								CERRAR
							</button>
						</div>
					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/outline'
import { XIcon } from '@heroicons/vue/solid'

export default {
	components: {
		Dialog,
		DialogOverlay,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
		CheckIcon,
		XIcon
	},
	setup(props, {emit}) {
		const open = ref(true);

		function close() {
			open.value = false;
			emit('close');
		}

		return {
			open,
			close
		}

	},
}
</script>

